@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
body {
  -ms-overflow-style: none;
}
body::-webkit-scrollbar {
  display: none;
  background-color: red;
}

.swiper-wrapper {
  padding-bottom: 30px;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px !important;
}

.swiper-button-prev,
.swiper-button-next {
  margin-top: -20% !important;
  color: #ffd368 !important;
}

.codex-editor__redactor {
  padding-bottom: 0px !important;
}

.image-tool__caption {
  display: none !important;
}

/* Cleaned up autofill styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-text-fill-color: #ffffff !important; /* Ensures text is white */
  -webkit-transition: background-color 5000s ease-in-out 0s !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

/* Removed redundant transition rules */
input:-webkit-autofill {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

/* Optional: Remove autofill styles for all browsers */
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  background-color: transparent !important;
}

.p-Input-input {
  background-color: #ffd369 !important;
}

.hubspot {
  display: none !important;
}
.swiper-scrollbar {
  top: 0 !important;
  background-color: #ffd36938 !important;
}
.swiper-scrollbar-drag {
  background-color: #ffd368 !important;
}

.swiper-pagination-bullet-active {
  background: #ffd369 !important;
}

.TrackListWidget_trackListGridContainer__GZGxh {
  background-color: #ffd368 !important;
}

.mapboxgl-canvas {
  width: 100% !important;
}

.embed-tool__caption {
  display: none;
}

.pintura-editor {
  --color-background: 0, 0, 0 !important;
  --color-foreground: 255, 255, 255 !important;
}

@media (prefers-color-scheme: dark) {
  .pintura-editor {
    --color-background: 0, 0, 0;
    --color-foreground: 255, 255, 255;
  }
}

:root {
  --pintura-color-background: #1e1e1e;
  --pintura-color-surface: #2c2c2c;
  --pintura-color-border: #444;
  --pintura-color-text: #f0f0f0;
  --pintura-color-accent: #4e9de7;
  --pintura-color-hover: #3c3c3c;
  --pintura-color-focus: #4e9de7;
  --pintura-control-color-background: #333;
  --pintura-control-color-background-hover: #555;
  /* Add other Pintura variables you need to customize */
}

.str-chat__container {
  flex-direction: column;
}

.str-chat__channel-list-messenger-react {
  padding-bottom: 0 !important;
}

.str-chat__send-button {
  color: #ffd369 !important;
  fill: #ffd369 !important;
}

.str-chat__message-reactions-button {
  height: 30px !important;
  width: 30px !important;
}

.str-chat__list {
  background-color: #1e1e1e !important;
}

.str-chat__message--me .str-chat__message-bubble {
  background-color: #fdbf49 !important;
  color: #1e1e1e !important;
}
.str-chat__message-bubble {
  background-color: #333333 !important;
}
.str-chat__message .str-chat__quoted-message-preview {
  border-radius: 5px !important;
  padding: 5px impor;
}

.str-chat__quoted-message-preview .str-chat__quoted-message-bubble {
  background-color: #1c1e22 !important;
  border-radius: 5px !important;
}
.str-chat__quoted-message-preview {
  background-color: #6161616b !important ;
}

.str-chat__message-textarea-container {
  align-items: flex-start !important;
}
.str-chat__message-action-icon {
  height: 20px !important;
  width: 20px !important;
  color: #ffd368 !important;
  stroke-width: 1px !important;
}

.str-chat__message-action-icon svg {
  color: #ffd368 !important;
  height: 20px !important;
  width: 20px !important;
  color: #ffd368 !important;
  stroke-width: 1px !important;
}

.str-chat__quoted-message-bubble {
  background-color: #444 !important;
}

.intercom-lightweight-app-launcher {
  bottom: 10% !important;
}
.intercom-app [class*='intercom-with-namespace-'] {
  bottom: 10% !important;
  max-height: 90% !important;
}

.scrollable-grid {
  will-change: transform;
  contain: strict;
}

.grid-list {
  width: 100% !important;
  display: flex;
  flex-direction: column;
}
.str-chat__message-input {
  background-color: #000 !important;
}

.str-chat__message-input .str-chat__message-input-inner {
  padding-bottom: 20px !important;
}

.str-chat__message-input .str-chat__send-button {
  color: #ffd368 !important;
}

.str-chat__message-input .str-chat__send-button svg path {
  color: #ffd368 !important;
  fill: #ffd368 !important;
}

.str-chat__message-input .str-chat__start-recording-audio-button svg path {
  fill: #fbfbfb !important;
}

.str-chat__message-input .str-chat__file-input-container svg path {
  fill: #fbfbfb !important;
}

.str-chat__message-input .str-chat__message-input-inner {
  align-items: flex-start !important;
}

